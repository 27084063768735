.edit-buttons {
    align-content: flex-end;
    width: 100%;
}
i {
    display: inline-block;
    width: 14px;
    height: 14px;
    *margin-right: .3em;
    line-height: 14px;
    vertical-align: text-top;
    background-image: url('../assets/glyphicons-halflings-white.png');
    background-position: 14px 14px;
    background-repeat: no-repeat;
    margin-top: 1px;
    align-content: space-between;
}
.icon-trash {
    background-position: -456px 0
}
.icon-edit {
    background-position: -96px -72px
}