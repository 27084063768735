body,
html {
    font-family: Raleway;
    margin: 0;
    padding: 0;
}
header {
    height: 120px;
    background-color: #d2c9c2;
}
footer {
    border-top: 1px solid #eee;
    text-align: right;
    color: #999;
    font-size: 13px;
    width: 1175px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}
.wrapper {
    width: 1190px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.content {
    margin: 30px 0 0 20px;
    float: left;
    width: 1150px;
}
.btn {
    border: 0;
    background: #d2c9c2;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #444;
    font-weight: 700;
    text-transform: uppercase;
    padding: 4px 32px;
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    text-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.btn-mini {
    padding: 0 6px;
}
.btn-primary {
    border: 0;
    background: #b59d81;
    color: #e7e3e0;
    text-transform: uppercase;
}
.btn-secondary {
    color: #b59d81;
    background: #e7e3e2;
}
.btn-success {
    background: #5bb75b;
}
.btn-warning {
    background: #faa732;
}
.btn-danger {
    background: #da4f49;
}
.btn.active,
.btn.disabled,
.btn:active,
.btn:focus,
.btn:hover,
.btn[disabled] {
    color: #444;
    background-color: #d2c9c2;
    opacity: 0.5;
}
.nav-tools {
    height: 38px;
    margin: 0 10px 0 10px;
    padding: 5px 0 0 10px;
    display: block;
    float: right;
}
.nav-tools i {
    margin-top: 5px;
}
.nav-call {
    float: right;
    margin-top: 8px;
    margin-right: -30px;
}
.nav-search {
    width: 580px;
    margin-top: 20px;
}
.nav-search img {
    margin-top: -15px;
    margin-right: -10px;
    float: left;
}
.nav-search form {
    float: right;
}
.nav-alias {
    background-image: linear-gradient(to right, #cab58a, #b59d81);
    padding: 0 16px 17px 0;
    border-radius: 0 0 15px 15px;
    -moz-border-radius: 0 0 15px 15px;
    -webkit-border-radius: 0 0 15px 15px;
    border: 0;
}
.nav-alias .user {
    padding-top: 0;
}
.nav-alias .user img {
    margin-right: -6px;
}
.nav-alias .user b {
    text-transform: uppercase;
    color: #e7e3e0;
}
.inline-group-form label {
    border-top: 1px solid #b59d81;
    border-bottom: 1px solid #b59d81;
    border-left: 1px solid #b59d81;
    border-right: 0;
    background: #fff;
    float: left;
    color: #b59d81;
    font-weight: 700;
    padding: 4px 4px 4px 10px;
    -webkit-border-radius: 12px 0 0 12px;
    -moz-border-radius: 12px 0 0 12px;
    border-radius: 12px 0 0 12px;
    cursor: inherit;
}
.inline-group-form input {
    border-top: 1px solid #b59d81;
    border-bottom: 1px solid #b59d81;
    border-left: 0;
    border-right: 0;
    background: #fff;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.inline-group-form button {
    border-top: 1px solid #b59d81;
    border-bottom: 1px solid #b59d81;
    border-left: 0;
    border-right: 1px solid #b59d81;
    background: #b59d81;
    -webkit-border-radius: 0 12px 12px 0;
    -moz-border-radius: 0 12px 12px 0;
    border-radius: 0 12px 12px 0;
    padding: 2px 12px 6px;
    font-size: 16px;
}
.inline-group-form button i {
    margin-top: 5px;
}
.home-news {
    float: right;
}
.home-left {
  width: 60%;
  float: left;
}
.home-left {
  font-family: 'Alegreya';
}
.home-right {
  float: right;
  width: 38%;
}
.home-cards-column {
  width: 25%;
  float:left;
}
.home-card {
  padding: 5px;
  margin: 5px;
  background-color: #d2c9c2;
  border-radius: 4px;
  text-align: center;
}
.home-card .home-card-image {
  height: 60px;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-card .home-card-name {
  font-size: 20px;
  padding: 30px 20px;
}
.home-card .home-card-action {
  width: -webkit-fill-available;
  font-family: 'Raleway';
  text-transform: none;
  padding: 4px;
  border-radius: 2px;
}
.home-document-modal {
  width: 800px;
  left: 40%;
}
.home-document-modal h2, .home-document-modal h3 {
  font-weight: bold;
  line-height: 20px;
  margin: 0;
  border-bottom: 0;
}
.home-document-modal h2 {
  font-size: 20px;
  font-family: 'Alegreya';
}
.home-document-modal h3 {
  font-size: 12px;
  text-transform: uppercase;
  color: #b59d81;
}
.home-document-modal p {
  margin-top: 10px;
  font-weight: bold;
  font-size: 14px;
  font-family: 'Alegreya';
}
.home-document-modal .home-document-modal-left {
  float:left;
  width: 65%;
}
.home-document-modal .home-document-modal-right {
  float:right;
  width: 30%;
  margin-right:10px;
}
.entrada {
  width: 80%;
  margin: 0 auto 20px;
  padding: 20px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #d2c9c2;
  border-radius: 6px;
  display: flow-root;
}
.entrada .entrada-texto h1 {
  font-size: 20px;
}
#jb-nav-bar {
    height: 41px;
    float: left;
    width: 1000px;
    margin: 0 0 0 -2px;
}
#jb-nav-bar ul {
    margin: 0;
    padding: 0;
}
#jb-nav-bar ul li {
    display: inline;
    margin: 0;
    padding: 0;
    list-style-type: none;
    border-right: 1px solid #ebebeb;
    height: 40px;
    float: left;
}
#logo {
    width: 179px;
    height: 66px;
    float: left;
    margin: 0;
}
.n1,
.n2,
.n3,
.n4,
.n5,
.n6 {
    display: none;
}
.clear {
    clear: both;
}
.breadcrumb {
    margin: 0;
    padding: 0;
    background-color: transparent;
}
.breadcrumb li {
    display: inline;
    list-style-type: none;
    color: #333;
    font-weight: 700;
}
.breadcrumb li a {
    text-decoration: none;
    color: #333;
    font-weight: 700;
}
.m30 {
    margin: 30px 0 0 0;
}
.rounded-div {
    width: 1090px;
    min-height: 175px;
    border: 1px solid #ebebeb;
    float: left;
    border-radius: 10px;
    padding: 7px 30px;
}
.clear-table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    border: 0;
}
.clear-table tr td {
    vertical-align: top;
}
.data-table {
    margin: 0 40px 0 0;
    padding: 0;
    border: 0;
    border-collapse: collapse;
}
.data-table tr {
    width: 500px;
}
.data-table tr td {
    font-size: 13px;
    vertical-align: top;
}
.data-table tr td a {
    color: #b43c2c;
}
.data-unit {
    padding: 9px 0 15px 10px;
    min-height: 20px;
}
.dot-line {
    background: repeat-x bottom left;
}
.data-separator {
    width: 30px;
}
.data-unit-bottom {
    padding: 9px 0 9px 10px;
    min-width: 150px;
}
.data-unit-bottom p {
    margin: 0;
}
.featured {
    font-weight: 700;
    color: #b43c2c;
}
legend {
    color: #b43c2c;
}
.rounded-tab {
    width: 160px;
    height: 25px;
    float: left;
    text-align: center;
    padding: 10px 0 0 0;
    font-size: 11px;
    margin: 0;
    cursor: pointer;
}
.tab-active {
    background: no-repeat;
    color: #fff;
}
.tab-inactive {
    background: no-repeat;
    color: #ccc;
}
.expediente-tab-content {
    padding: 10px 0;
}
.expediente-tab-content p {
    margin: 0;
    font-size: 13px;
}
.toolbar {
    margin: 20px 0 0 0;
}
#tab-actions {
    float: left;
    margin-left: 20px;
    width: 100px;
    display: block;
}
#tab-actions img {
    cursor: pointer;
}
.inline-field,
.inline-field-hon {
    display: inline;
}
.inline-field label,
.inline-field-hon label {
    display: inline;
    font-size: 12px;
}
.inline-field input,
.inline-field select {
    font-size: 12px;
    margin-top: 10px;
    margin-right: 12px;
}
.inline-field-hon input,
.inline-field-hon select {
    font-size: 12px;
    margin-top: 0;
    margin-right: 12px;
}
.inline-field-hon .add-on {
    font-size: 12px;
    margin-top: 0;
}
.inline-field select#idetapa {
    width: 140px;
}
.breadcrumb-action {
    float: right;
    margin: -22px 0 0 0;
}
.breadcrumb-action img {
    cursor: pointer;
}
.user {
    padding-top: 5px;
}
.user i {
    margin-top: 0;
}
.user b {
    color: #8ec163;
}
.gray-note {
    color: #ccc;
    text-decoration: none;
    border: 0;
    font-size: 18px;
}
tr.file td img {
    vertical-align: middle;
    height: 32px;
}
th.col-70 {
    width: 75px;
}
.library-nav {
    margin: 0;
}
.library-nav li {
    float: left;
    list-style: none;
    margin: 0 20px 10px 0;
}
#addFile,
#addFolder {
    display: none;
}
#pieJuzgados,
#pieLey,
#pieStatus {
    width: 500px;
    height: 500px;
    margin: 20px auto 10px auto;
    border: 1px solid #ebebeb;
    font-size: 16px;
}
div.resume {
    margin: 20px;
    border: 1px solid #333;
    padding: 0;
    width: 475px;
}
div.resume h2 {
    font-size: 15px;
    background: #1e70c0;
    width: 455px;
    padding: 0;
    color: #eee;
    padding: 2px 10px;
}
div.resume h3 {
    font-size: 14px;
    background: #8ec163;
    width: 455px;
    color: #eee;
    padding: 2px 10px;
    margin: 0;
}
div.resume div.detail {
    width: 430px;
    float: left;
    border-bottom: 1px solid #eee;
    margin: 0 0 0 30px;
}
div.resumeGrande {
    width: 950px;
}
div.resumeGrande h2 {
    width: 930px;
}
.titleNovedades {
    font-size: 40px;
}
.llamadaEquipoColor0 {
    background-color: #f6ddcc;
}
.llamadaEquipoColor1 {
    background-color: #ebdef0;
}
.llamadaEquipoColor2 {
    background-color: #d4e6f1;
}
.llamadaEquipoColor3 {
    background-color: #fae5d3;
}
.llamadaEquipoColor4 {
    background-color: #d4efdf;
}
.llamadaEquipoColor5 {
    background-color: #fcf3cf;
}
.llamadaEquipoColor6 {
    background-color: #eaeded;
}
.llamadaEquipoColor7 {
    background-color: #f2d7d5;
}
.llamadaEquipoColor8 {
    background-color: #d1f2eb;
}
.tableDetail {
    display: none;
}