label, select, input[type=text], input[type=email], input[type=tel], input[type=number], input[type=date] {
    /* font-family: auto; */
    font-size:0.75rem;
    color: rgb(102, 102, 102);    
}
label {
    cursor: pointer;
}
.row {
    margin-bottom: 0.8rem;
}
select, input[type=text], input[type=email], input[type=tel], input[type=number], input[type=date], .input-group > * {
    height: 1.875rem;
    font-size:0.75rem;
}

.flex {
    display: flex !important;
}
.activeLink {
    font-weight: bolder !important;
    color: rgb(181, 157, 129) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 1.25rem;
    font-family: 'Alegreya';
    font-weight: 400;
    line-height: 1.25rem;
    color: #333;
    text-rendering: optimizelegibility;
    border-bottom: 1px solid #eee
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    font-weight: 400;
    line-height: 1;
    color: #999
}

h1,
h2,
h3 {
    line-height: 2.5rem
}

h1 {
    font-size: 2rem
}

h2 {
    font-size: 1.75rem
}

h3 {
    font-size: 1.5rem
}
h3 > img {
    width: 2rem;
}

h4 {
    font-size: 1.25rem
}

h5 {
    font-size: 1rem
}

h6 {
    font-size: 0.8125rem
}

h1 small {
    font-size: 1.75rem
}

h2 small {
    font-size: 1.25rem
}

h3 small,
h4 small {
    font-size: 1rem
}

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

.well blockquote {
    border-color: #ddd;
    border-color: rgba(0, 0, 0, .15)
}

.well-large {
    padding: 24px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px
}

.well-small {
    padding: 9px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}
